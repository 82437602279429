import profileDefaultConfig from './ProfileDefaultConfig'
import axiosIns from '@/libs/axios'

class CustomersService {
    config = { ...profileDefaultConfig }

    axios = null
    
    constructor() {
        this.axios = axiosIns
    }

    async handleEdit(id) {
        const result = await this.axios.get(`${this.config.editEndpoint}${id}`)

        return result
    }

    async handleUpdate(id, data) {
        const result = await this.axios.put(`${this.config.updateEndpoint}${id}`, data)

        return result
    }

    async handleChangeAvatar(id, data) {
        const result = await this.axios.post(`${this.config.changeAvatarEndpoint}${id}`, data)

        return result
    }

    async handleChangePassword(data) {
        const result = await this.axios.post(this.config.changePasswordEndpoint, data)

        return result
    }
}

export default new CustomersService()
