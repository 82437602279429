<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label>Contraseña</label>
            </div>
            <validation-provider
              #default="{ errors }"
              name="contraseña"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  v-model="password"
                  class="form-control-merge"
                  :type="root === 'profile' ? passwordFieldType : 'text'"
                  name="password"
                  :placeholder="root === 'profile' ? '············' : 'Contreña'"
                />
                <b-input-group-append
                  v-if="root === 'profile'"
                  is-text
                >
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label>Confirmación de contraseña</label>
            </div>
            <validation-provider
              #default="{ errors }"
              name="confirmación de contraseña"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  v-model="password_confirmation"
                  class="form-control-merge"
                  :type="root === 'profile' ? passwordFieldType : 'text'"
                  name="password_confirmation"
                  :placeholder="root === 'profile' ? '············' : 'Confirmación de contraseña'"
                />
                <b-input-group-append
                  v-if="root === 'profile'"
                  is-text
                >
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            v-if="root === 'profile' ? accessPermissions('users.password') : accessPermissions('users.password.users')"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            @click="handleReturn"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import router from '@/router'
import ProfileService from '../services/ProfileService'
import AgentsServices from '@/pages/dashboard/settings/agents/services/AgentsServices'
import UsersServices from '@/pages/dashboard/settings/users/services/UsersServices'
import useJwt from '@/auth/jwt/useJwt'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'FormPasswordComponent',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BButton,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    root: {
      type: String,
      required: false,
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: '',
      password_confirmation: '',
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.password = ''
      this.password_confirmation = ''
      this.$refs.formValidation.reset()
    },
    handleReturn() {
      if (this.root === 'agent') {
        router.push({ name: 'settings-agents' })
      } else {
        router.push({ name: 'main' })
      }
    },
    async handleLogout() {
      await useJwt.logout().then(response => {
        router.push({ name: 'login' })
      }).catch(error => this.errorResp(error))
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const data = {
            password: this.password,
            password_confirmation: this.password_confirmation,
          }

          if (this.root === 'profile' && this.accessPermissions('users.password')) {
            ProfileService.handleChangePassword(data).then(response => {
              if (response.data.data) {
                this.clearForm()
                this.swal('La contraseña ha sido actualizada correctamente', 'success')
                this.handleLogout()
              }
            }).catch(error => this.errorResp(error))
          }

          if (this.id !== undefined && this.root === 'agent' && this.accessPermissions('users.password.users')) {
            AgentsServices.handleChangePassword(this.id, data).then(response => {
              if (response.data.data) {
                this.clearForm()
                this.swal('La contraseña ha sido actualizada correctamente', 'success')
                router.push({ name: 'settings-agents' })
              }
            }).catch(error => this.errorResp(error))
          }

          if (this.id !== undefined && this.root === 'user' && this.accessPermissions('users.global.password.users')) {
            UsersServices.handleChangePassword(this.id, data).then(response => {
              if (response.data.data) {
                this.clearForm()
                this.swal('La contraseña ha sido actualizada correctamente', 'success')
                router.push({ name: 'settings-users' })
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
